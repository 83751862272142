import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import './prag-mit-zuza-legal.css'

const PRAGMitZUZALegal = (props) => {
  return (
    <div className="prag-mit-zuza-legal-container">
      <Helmet>
        <title>PRAG-mit-ZUZA-Legal - Travel Agency</title>
        <meta
          property="og:title"
          content="PRAG-mit-ZUZA-Legal - Travel Agency"
        />
      </Helmet>
      <nav data-role="Header" className="prag-mit-zuza-legal-navbar">
        <Link to="/" className="prag-mit-zuza-legal-navlink">
          <h1 className="prag-mit-zuza-legal-logo">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
        </Link>
        <div className="prag-mit-zuza-legal-right-side">
          <div className="prag-mit-zuza-legal-links-container">
            <Link
              to="/prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung"
              className="prag-mit-zuza-legal-navlink1"
            >
              Für Schulen
            </Link>
          </div>
          <div className="prag-mit-zuza-legal-container1">
            <Link
              to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
              className="prag-mit-zuza-legal-navlink2 Button button"
            >
              Termin buchen
            </Link>
          </div>
        </div>
        <div data-role="BurgerMenu" className="prag-mit-zuza-legal-burger-menu">
          <svg
            viewBox="0 0 1024 1024"
            className="prag-mit-zuza-legal-burger-menu1"
          >
            <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="prag-mit-zuza-legal-mobile-menu">
          <div className="prag-mit-zuza-legal-nav">
            <div className="prag-mit-zuza-legal-top">
              <h1>Travel</h1>
              <div
                data-role="CloseMobileMenu"
                className="prag-mit-zuza-legal-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="prag-mit-zuza-legal-icon03"
                >
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="prag-mit-zuza-legal-right-side1">
              <div className="prag-mit-zuza-legal-links-container1">
                <span className="prag-mit-zuza-legal-text02">Home</span>
                <span className="prag-mit-zuza-legal-text03">About</span>
                <span className="prag-mit-zuza-legal-text04">
                  Tour Packages
                </span>
                <span>Contact</span>
              </div>
              <SolidButton button="Explore places"></SolidButton>
            </div>
          </div>
          <div className="prag-mit-zuza-legal-follow-container">
            <span className="prag-mit-zuza-legal-text06">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <div className="prag-mit-zuza-legal-icons-container">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-legal-link"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="prag-mit-zuza-legal-icon05"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-legal-link1"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="prag-mit-zuza-legal-icon07"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-legal-link2"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="prag-mit-zuza-legal-icon09"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="prag-mit-zuza-legal-container2">
        <div className="prag-mit-zuza-legal-container3 thq-section-padding">
          <div className="prag-mit-zuza-legal-max-width thq-section-max-width">
            <div className="prag-mit-zuza-legal-content">
              <h2 className="prag-mit-zuza-legal-heading1 thq-heading-2">
                <span>Terms and Conditions</span>
                <br></br>
              </h2>
              <p className="prag-mit-zuza-legal-content1 thq-body-large">
                Welcome to our website. By accessing and using our services, you
                agree to comply with and be bound by the following terms and
                conditions. If you disagree with any part of these terms, please
                do not use our website.
              </p>
            </div>
          </div>
        </div>
        <div className="prag-mit-zuza-legal-container4 thq-section-padding">
          <div className="prag-mit-zuza-legal-max-width1 thq-section-max-width">
            <div className="prag-mit-zuza-legal-content1 thq-flex-column">
              <ul className="prag-mit-zuza-legal-ul thq-flex-column">
                <li className="prag-mit-zuza-legal-li list-item">
                  <h3 className="prag-mit-zuza-legal-heading2 thq-heading-3">
                    Definitions
                  </h3>
                  <p>
                    <span className="prag-mit-zuza-legal-text09">Service</span>
                    <span>
                      {' '}
                      refers to the website and any services provided by Ing.
                      Zuzana Manová.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text12">
                      User
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      refers to any individual or entity using our service.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text15">
                      We/Us/Our
                    </span>
                    <span>
                      {' '}
                      refers to Ing. Zuzana Manová and the associated business.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="prag-mit-zuza-legal-max-width2 thq-section-max-width">
            <div className="thq-body-small prag-mit-zuza-legal-content2 prag-mit-zuza-legal-content2 thq-flex-column">
              <ul className="prag-mit-zuza-legal-ul1 thq-flex-column">
                <li className="prag-mit-zuza-legal-li1 list-item">
                  <h3 className="prag-mit-zuza-legal-heading21 thq-heading-3">
                    Use of the Service
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      You agree to use our service only for lawful purposes and
                      in a way that does not infringe the rights of, restrict,
                      or inhibit anyone else’s use and enjoyment of the service.
                      Prohibited behavior includes harassing or causing distress
                      or inconvenience to any other user, transmitting obscene
                      or offensive content, or disrupting the normal flow of
                      dialogue within our service.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="prag-mit-zuza-legal-max-width3 thq-section-max-width">
            <div className="prag-mit-zuza-legal-content3 thq-flex-column">
              <ul className="prag-mit-zuza-legal-ul2 thq-flex-column">
                <li className="prag-mit-zuza-legal-li2 list-item">
                  <h3 className="prag-mit-zuza-legal-heading22 thq-heading-3">
                    Intellectual Property
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      All content included on the website, such as text,
                      graphics, logos, images, and software, is the property of
                      Ing. Zuzana Manová or its content suppliers and is
                      protected by applicable intellectual property laws.
                      Unauthorized use of this content is prohibited.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="prag-mit-zuza-legal-max-width4 thq-section-max-width">
            <div className="prag-mit-zuza-legal-content4 thq-flex-column">
              <ul className="prag-mit-zuza-legal-ul3 thq-flex-column">
                <li className="prag-mit-zuza-legal-li3 list-item">
                  <h3 className="prag-mit-zuza-legal-heading23 thq-heading-3">
                    Privacy Policy
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      Our Privacy Policy outlines how we handle your personal
                      information. By using our service, you consent to the
                      collection, use, and disclosure of your information as
                      described in our Privacy Policy.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="prag-mit-zuza-legal-max-width5 thq-section-max-width">
            <div className="prag-mit-zuza-legal-content5 thq-flex-column">
              <ul className="prag-mit-zuza-legal-ul4 thq-flex-column">
                <li className="prag-mit-zuza-legal-li4 list-item">
                  <h3 className="prag-mit-zuza-legal-heading24 thq-heading-3">
                    GDPR Compliance
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      We are committed to ensuring the security and protection
                      of the personal information we process, and to provide a
                      compliant and consistent approach to data protection in
                      accordance with the General Data Protection Regulation
                      (GDPR). We have processes in place to identify, assess,
                      and respond to any personal data breaches.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text31">
                      Your Rights Under GDPR:
                    </span>
                    <br className="prag-mit-zuza-legal-text32"></br>
                    <span className="prag-mit-zuza-legal-text33">
                      Right to Access:
                    </span>
                    <span>
                      {' '}
                      You have the right to request access to your personal
                      data.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text36">
                      Right to Rectification:
                    </span>
                    <span>
                      {' '}
                      You can request correction of inaccurate or incomplete
                      data.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text39">
                      Right to Erasure:
                    </span>
                    <span>
                      {' '}
                      You can request the deletion of your personal data, where
                      applicable.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text42">
                      Right to Restrict Processing:
                    </span>
                    <span>
                      {' '}
                      You have the right to request the restriction of your
                      personal data processing.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text45">
                      Right to Data Portability:
                    </span>
                    <span>
                      {' '}
                      You have the right to request transfer of your personal
                      data to another service provider.
                    </span>
                    <br></br>
                    <span className="prag-mit-zuza-legal-text48">
                      Right to Object:
                    </span>
                    <span>
                      {' '}
                      You can object to the processing of your personal data
                      under certain conditions.
                    </span>
                    <br></br>
                    <span>
                      If you wish to exercise any of these rights, please
                      contact us at the details provided below.
                    </span>
                    <br></br>
                    <br></br>
                    <br></br>
                  </p>
                  <h3 className="prag-mit-zuza-legal-heading25 thq-heading-3">
                    Limitation of Liability
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      We will not be liable for any loss or damage of any kind,
                      including without limitation, indirect or consequential
                      loss or damage, arising out of or in connection with the
                      use of our service. This includes, but is not limited to,
                      any loss of data, loss of profits, or loss of business
                      opportunities.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                  <h3 className="prag-mit-zuza-legal-heading26 thq-heading-3">
                    Changes to Terms and Conditions
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      We reserve the right to amend these terms and conditions
                      at any time. Any changes will be posted on this page, and
                      your continued use of the service constitutes acceptance
                      of those changes.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                  <h3 className="prag-mit-zuza-legal-heading27 thq-heading-3">
                    Governing Law
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      These terms and conditions are governed by and construed
                      in accordance with the laws of the Czech Republic. Any
                      disputes relating to these terms and conditions will be
                      subject to the exclusive jurisdiction of the courts of the
                      Czech Republic.
                    </span>
                    <br></br>
                    <br></br>
                  </p>
                  <h3 className="prag-mit-zuza-legal-heading28 thq-heading-3">
                    Contact Information
                  </h3>
                  <p className="thq-body-small">
                    <span>
                      If you have any questions about these Terms and
                      Conditions, please contact us at:
                    </span>
                    <br></br>
                    <br></br>
                    <span>Name: Ing. Zuzana Manová</span>
                    <br></br>
                    <span>
                      Address: Praha - Žižkov, Na vápence 2443/25, 13000
                    </span>
                    <br></br>
                    <span>Email: zuzanamanova@email.cz</span>
                    <br></br>
                    <span>Phone: 721 23 19 33</span>
                    <br></br>
                    <br></br>
                    <br></br>
                    <span>
                      Obchodní firma Ing. Zuzana Manová se sídlem Praha -
                      Žižkov, Na vápence 2443/25, 13000 a identifikačním číslem
                      (IČO) 18897398
                    </span>
                    <br></br>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuza-legal-footer">
        <div className="prag-mit-zuza-legal-menu">
          <h1 className="prag-mit-zuza-legal-logo2">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
          <div className="prag-mit-zuza-legal-links-container2">
            <div className="prag-mit-zuza-legal-container5">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-touren"
                className="prag-mit-zuza-legal-navlink3"
              >
                Tour packages
              </Link>
            </div>
            <div className="prag-mit-zuza-legal-container6">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuza-legal-navlink4"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PRAGMitZUZALegal
