import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import PRAGMitZUZAExkluzivPrivateTourenTouren from './views/prag-mit-zuza-exkluziv-private-touren-touren'
import PRAGMitZUZAExkluzivPrivateTourenCONTACTPAGE from './views/prag-mit-zuza-exkluziv-private-touren-contact-page'
import PRAGMitZUZALegal from './views/prag-mit-zuza-legal'
import PRAGMitZUZAReview from './views/prag-mit-zuza-review'
import PRAGMitZUZAFrSchulenUndKlassenfahrtenMageschneiderteReisenNachPragMitKomplettemProgrammUnterkunftUndVerpflegung from './views/prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung'
import PRAGMitZUZAExkluzivPrivateTouren from './views/prag-mit-zuza-exkluziv-private-touren'
import PRAGMitZUZABLOG from './views/prag-mit-zuza-blog'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          component={PRAGMitZUZAExkluzivPrivateTourenTouren}
          exact
          path="/prag-mit-zuza-exkluziv-private-touren-touren"
        />
        <Route
          component={PRAGMitZUZAExkluzivPrivateTourenCONTACTPAGE}
          exact
          path="/prag-mit-zuza-exkluziv-private-touren-contact-page"
        />
        <Route component={PRAGMitZUZALegal} exact path="/prag-mit-zuza-legal" />
        <Route
          component={PRAGMitZUZAReview}
          exact
          path="/prag-mit-zuza-review"
        />
        <Route
          component={
            PRAGMitZUZAFrSchulenUndKlassenfahrtenMageschneiderteReisenNachPragMitKomplettemProgrammUnterkunftUndVerpflegung
          }
          exact
          path="/prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung"
        />
        <Route component={PRAGMitZUZAExkluzivPrivateTouren} exact path="/" />
        <Route component={PRAGMitZUZABLOG} exact path="/prag-mit-zuza-blog" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
