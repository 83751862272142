import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import './prag-mit-zuza-review.css'

const PRAGMitZUZAReview = (props) => {
  return (
    <div className="prag-mit-zuza-review-container">
      <Helmet>
        <title>PRAG-mit-ZUZA-Review - Travel Agency</title>
        <meta
          property="og:title"
          content="PRAG-mit-ZUZA-Review - Travel Agency"
        />
      </Helmet>
      <nav data-role="Header" className="prag-mit-zuza-review-navbar">
        <Link to="/" className="prag-mit-zuza-review-navlink">
          <h1 className="prag-mit-zuza-review-logo">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
        </Link>
        <div className="prag-mit-zuza-review-right-side">
          <div className="prag-mit-zuza-review-links-container">
            <Link
              to="/prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung"
              className="prag-mit-zuza-review-navlink1"
            >
              Für Schulen
            </Link>
            <Link to="/" className="prag-mit-zuza-review-navlink2">
              Über mich
            </Link>
            <Link
              to="/prag-mit-zuza-exkluziv-private-touren-touren"
              className="prag-mit-zuza-review-navlink3"
            >
              Tour Packages
            </Link>
          </div>
          <div className="prag-mit-zuza-review-container01">
            <Link
              to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
              className="prag-mit-zuza-review-navlink4 Button button"
            >
              Termin buchen
            </Link>
          </div>
        </div>
        <div
          data-role="BurgerMenu"
          className="prag-mit-zuza-review-burger-menu"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="prag-mit-zuza-review-burger-menu1"
          >
            <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
          </svg>
        </div>
        <div
          data-role="MobileMenu"
          className="prag-mit-zuza-review-mobile-menu"
        >
          <div className="prag-mit-zuza-review-nav">
            <div className="prag-mit-zuza-review-top">
              <h1>Travel</h1>
              <div
                data-role="CloseMobileMenu"
                className="prag-mit-zuza-review-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="prag-mit-zuza-review-icon03"
                >
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="prag-mit-zuza-review-right-side1">
              <div className="prag-mit-zuza-review-links-container1">
                <span className="prag-mit-zuza-review-text02">Home</span>
                <span className="prag-mit-zuza-review-text03">About</span>
                <span className="prag-mit-zuza-review-text04">
                  Tour Packages
                </span>
                <span>Contact</span>
              </div>
              <SolidButton button="Explore places"></SolidButton>
            </div>
          </div>
          <div className="prag-mit-zuza-review-follow-container">
            <span className="prag-mit-zuza-review-text06">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <div className="prag-mit-zuza-review-icons-container">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="prag-mit-zuza-review-icon05"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link1"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="prag-mit-zuza-review-icon07"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link2"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="prag-mit-zuza-review-icon09"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="prag-mit-zuza-review-container02 thq-section-padding">
        <div className="prag-mit-zuza-review-max-width thq-section-max-width">
          <h2 className="prag-mit-zuza-review-text07 thq-heading-2">
            <span>
              Hallo!
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              Ich würde mich freuen, wenn Sie eine Bewertung für mich abgeben
              könnten 😊
            </span>
          </h2>
          <div className="prag-mit-zuza-review-container03">
            <div className="prag-mit-zuza-review-container04">
              <a
                href="https://g.page/r/Cbb9a9sHgAK7EBM/review"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link3"
              >
                <img alt="Partner 3 Logo" src="/google-review-logo-200h.png" />
              </a>
            </div>
            <div className="prag-mit-zuza-review-container05">
              <img
                alt="Partner 4 Logo"
                src="/svg-image-1.svg"
                className="prag-mit-zuza-review-logo4 thq-img-ratio-16-9"
              />
            </div>
          </div>
          <h2 className="prag-mit-zuza-review-text11 thq-heading-2">
            Vielen Dank und bis bald! Ahoj!! 
          </h2>
        </div>
      </div>
      <div className="thq-section-padding prag-mit-zuza-review-testimonial1">
        <div className="prag-mit-zuza-review-max-width1 thq-section-max-width">
          <svg viewBox="0 0 1024 1024" className="prag-mit-zuza-review-icon11">
            <path d="M950.857 340.571c0-160.571-108.571-194.286-200-194.286-85.143 0-181.143 92-210.857 127.429-13.714 16.571-42.286 16.571-56 0-29.714-35.429-125.714-127.429-210.857-127.429-91.429 0-200 33.714-200 194.286 0 104.571 105.714 201.714 106.857 202.857l332 320 331.429-319.429c1.714-1.714 107.429-98.857 107.429-203.429zM1024 340.571c0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286s-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429z"></path>
          </svg>
          <div className="prag-mit-zuza-review-section-title">
            <h2 className="prag-mit-zuza-review-title thq-heading-2">
              Die Erfahrungen meiner Kunden:
            </h2>
            <p className="prag-mit-zuza-review-subtitle thq-body-large">
              <span className="prag-mit-zuza-review-text12">
                &quot;Ich habe im Laufe der Jahre Hunderte von Touren gemacht.
                Ihre wirklich glücklichen Bewertungen ermöglichen es mir, mit
                mehr wunderbaren Menschen wie Ihnen in Kontakt zu treten.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="prag-mit-zuza-review-text13">
                Ich danke Ihnen!
              </span>
              <span className="prag-mit-zuza-review-text14">&quot; </span>
              <span>- Z.</span>
            </p>
          </div>
          <div className="prag-mit-zuza-review-container06 thq-flex-row">
            <div className="prag-mit-zuza-review-content thq-flex-column">
              <div className="prag-mit-zuza-review-stars">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
              </div>
              <p className="prag-mit-zuza-review-text16 thq-body-large">
                Hallo Zuzanna, danke für die tolle Stadttour mit dir. Wir haben
                uns sehr gut aufgehoben gefühlt. Die Tour war sehr informativ
                hatte sehr mit viel Hintergrundwissen. Besonders deine charmante
                Art hat uns immer ein lächeln ins Gesicht gezaubert. Vielen Dank
                und weiter so.....übrigens Bier aus dem Tank war der Tipp des
                Tages. 😊
              </p>
              <a
                href="https://www.tripadvisor.de/Profile/heimdall1976?fid=0dec66d5-e017-43d0-9ee5-88bfd2e27537"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link4"
              >
                <div className="prag-mit-zuza-review-avatar">
                  <img
                    alt="Bild von Anna Müller"
                    src="https://images.unsplash.com/photo-1543002588-bfa74002ed7e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGJvb2t8ZW58MHx8fHwxNzIwMzkxNDMyfDA&amp;ixlib=rb-4.0.3&amp;w=400"
                    className="prag-mit-zuza-review-avatar-image thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="prag-mit-zuza-review-avatar-content">
                    <span className="prag-mit-zuza-review-text17 thq-body-small">
                      Lars
                    </span>
                    <span className="thq-body-small">---</span>
                  </div>
                  <img
                    alt="Logo von Reiseblog XYZ"
                    src="/svg-image-1.svg"
                    className="prag-mit-zuza-review-logo2"
                  />
                </div>
              </a>
            </div>
            <div className="prag-mit-zuza-review-content1 thq-flex-column">
              <div className="prag-mit-zuza-review-stars1">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                </svg>
              </div>
              <p className="prag-mit-zuza-review-text19 thq-body-large">
                Recht herzlichen Dank für die tolle und sehr schöne
                Stadtführung, liebe Zuzana, das hast du sehr gut gemacht. Es war
                eine super individuelle Führung. Wir werden uns beim nächsten
                mal wieder melden. Das ist so viel schöner und individueller.
                Ich wünsche dir, Zuzana alles Gute und eine gute Zeit. Viele
                Grüße C.T.
              </p>
              <a
                href="https://www.tourhq.com/guide/cz56896/zuzana-manova"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link5"
              >
                <div className="prag-mit-zuza-review-avatar1">
                  <img
                    alt="Bild von Max Mustermann"
                    src="https://images.unsplash.com/photo-1531884070720-875c7622d4c6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fGVhZ2xlJTIwfGVufDB8fHx8MTcyMDM5MTU1NXww&amp;ixlib=rb-4.0.3&amp;w=400"
                    className="prag-mit-zuza-review-avatar-image1 thq-img-round"
                  />
                  <div className="prag-mit-zuza-review-avatar-content1">
                    <span className="prag-mit-zuza-review-text20 thq-body-small">
                      Christian
                    </span>
                    <span className="thq-body-small">---</span>
                  </div>
                  <img
                    alt="Logo von Universität ABC"
                    src="/logo-1500h.png"
                    className="prag-mit-zuza-review-logo3 thq-img-ratio-16-9 prag-mit-zuza-review-logo3"
                  />
                </div>
              </a>
            </div>
          </div>
          <svg viewBox="0 0 1024 1024" className="prag-mit-zuza-review-icon33">
            <path d="M950.857 340.571c0-160.571-108.571-194.286-200-194.286-85.143 0-181.143 92-210.857 127.429-13.714 16.571-42.286 16.571-56 0-29.714-35.429-125.714-127.429-210.857-127.429-91.429 0-200 33.714-200 194.286 0 104.571 105.714 201.714 106.857 202.857l332 320 331.429-319.429c1.714-1.714 107.429-98.857 107.429-203.429zM1024 340.571c0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286s-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429z"></path>
          </svg>
        </div>
      </div>
      <div className="prag-mit-zuza-review-contact1 thq-section-padding">
        <div className="prag-mit-zuza-review-max-width2 thq-flex-row thq-section-max-width">
          <div className="prag-mit-zuza-review-section-title1 thq-flex-column">
            <div className="prag-mit-zuza-review-content2">
              <h2 className="prag-mit-zuza-review-text22 thq-heading-2">
                Nehmen Sie Kontakt mit mir auf
              </h2>
            </div>
            <div className="prag-mit-zuza-review-content3">
              <div className="prag-mit-zuza-review-row">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <span className="thq-body-small prag-mit-zuza-review-email">
                  zuzanamanova@email.cz
                </span>
              </div>
              <div className="prag-mit-zuza-review-row1">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
                </svg>
                <span className="thq-body-small prag-mit-zuza-review-phone">
                  +420 721 23 19 33
                </span>
              </div>
              <div className="prag-mit-zuza-review-row2">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                </svg>
                <span className="thq-body-small prag-mit-zuza-review-address">
                  Na Vápence 2443/25, 130 00 Praha 3, Czech Republic
                </span>
              </div>
            </div>
          </div>
          <form
            enctype="application/x-www-form-urlencoded"
            className="prag-mit-zuza-review-form thq-flex-column"
          >
            <div className="prag-mit-zuza-review-container07">
              <div className="prag-mit-zuza-review-input">
                <label
                  htmlFor="contact-form-7-first-name"
                  className="thq-body-small prag-mit-zuza-review-text23"
                >
                  Vorname
                </label>
                <input
                  type="text"
                  id="contact-form-7-first-name"
                  placeholder="First Name"
                  className="thq-input"
                />
              </div>
              <div className="prag-mit-zuza-review-input1">
                <label
                  htmlFor="contact-form-7-last-name"
                  className="thq-body-small prag-mit-zuza-review-text24"
                >
                  Nachname
                </label>
                <input
                  type="text"
                  id="contact-form-7-last-name"
                  placeholder="Last Name"
                  className="thq-input"
                />
              </div>
            </div>
            <div className="prag-mit-zuza-review-container08">
              <div className="prag-mit-zuza-review-input2">
                <label
                  htmlFor="contact-form-7-email"
                  className="thq-body-small prag-mit-zuza-review-text25"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="contact-form-7-email"
                  required="true"
                  placeholder="Email"
                  className="thq-input"
                />
              </div>
              <div className="prag-mit-zuza-review-input3">
                <label
                  htmlFor="contact-form-7-phone"
                  className="thq-body-small prag-mit-zuza-review-text26"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="contact-form-7-phone"
                  placeholder="Phone Number"
                  className="thq-input"
                />
              </div>
            </div>
            <div className="prag-mit-zuza-review-container09">
              <div className="prag-mit-zuza-review-input4">
                <label
                  htmlFor="contact-form-7-options"
                  className="thq-body-small prag-mit-zuza-review-text27"
                >
                  Wählen Sie ein Thema
                </label>
                <select id="contact-form-7-options" className="thq-select">
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Feedback">Sonstiges</option>
                  <option value="Option 2">Kundenbetreuung</option>
                  <option value="Option 2">Request for a Quote</option>
                  <option value="Option 2">School Trip</option>
                  <option value="Option 3">Feature request</option>
                </select>
              </div>
            </div>
            <div className="prag-mit-zuza-review-container10">
              <div className="prag-mit-zuza-review-input5">
                <label
                  htmlFor="contact-form-7-message"
                  className="thq-body-small prag-mit-zuza-review-text28"
                >
                  Message
                </label>
                <textarea
                  id="contact-form-7-message"
                  rows="3"
                  placeholder="Enter your message"
                  className="thq-input"
                ></textarea>
              </div>
            </div>
            <div className="prag-mit-zuza-review-checkbox">
              <input
                type="checkbox"
                id="contact-form-7-check"
                checked="true"
                required="true"
                className="thq-checkbox"
              />
              <label htmlFor="contact-form-7-check" className="thq-body-small">
                I accept the Terms
              </label>
            </div>
            <button
              type="submit"
              className="prag-mit-zuza-review-button thq-button-filled"
            >
              <span className="thq-body-small">Contact Me</span>
            </button>
          </form>
        </div>
      </div>
      <div className="prag-mit-zuza-review-footer">
        <div className="prag-mit-zuza-review-menu">
          <h1 className="prag-mit-zuza-review-logo4">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
          <div className="prag-mit-zuza-review-links-container2">
            <div className="prag-mit-zuza-review-container11">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-touren"
                className="prag-mit-zuza-review-navlink5"
              >
                Tour packages
              </Link>
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuza-review-navlink6"
              >
                Personalized offers
              </Link>
            </div>
            <div className="prag-mit-zuza-review-container12">
              <Link
                to="/prag-mit-zuza-legal"
                className="prag-mit-zuza-review-navlink7"
              >
                Terms and conditions
              </Link>
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuza-review-navlink8"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="prag-mit-zuza-review-follow-container1">
            <span className="prag-mit-zuza-review-text32">
              Follow my social media
            </span>
            <div className="prag-mit-zuza-review-icons-container1">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link6"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="prag-mit-zuza-review-icon41"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-review-link7"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="prag-mit-zuza-review-icon43"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PRAGMitZUZAReview
