import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import './prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung.css'

const PRAGMitZUZAFrSchulenUndKlassenfahrtenMageschneiderteReisenNachPragMitKomplettemProgrammUnterkunftUndVerpflegung =
  (props) => {
    return (
      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container">
        <Helmet>
          <title>
            PRAG-mit-ZUZA-Fr-Schulen-und-Klassenfahrten-mageschneiderte-Reisen-nach-Prag-mit-komplettem-Programm-Unterkunft-und-Verpflegung
            - Travel Agency
          </title>
          <meta
            property="og:title"
            content="PRAG-mit-ZUZA-Fr-Schulen-und-Klassenfahrten-mageschneiderte-Reisen-nach-Prag-mit-komplettem-Programm-Unterkunft-und-Verpflegung - Travel Agency"
          />
        </Helmet>
        <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container01">
          <nav
            data-role="Header"
            className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-navbar"
          >
            <h1 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-logo">
              <span>PRAG mit ZUZA</span>
              <br></br>
            </h1>
            <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-right-side">
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-links-container">
                <Link
                  to="/"
                  className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-navlink"
                >
                  Home
                </Link>
              </div>
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container02">
                <a
                  href="#quote"
                  className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link Button button"
                >
                  Termin buchen
                </a>
              </div>
            </div>
            <div
              data-role="BurgerMenu"
              className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-burger-menu"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-burger-menu1"
              >
                <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
                <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
                <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              </svg>
            </div>
            <div
              data-role="MobileMenu"
              className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-mobile-menu"
            >
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-nav">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-top">
                  <h1>Travel</h1>
                  <div
                    data-role="CloseMobileMenu"
                    className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-close-menu"
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon03"
                    >
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-right-side1">
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-links-container1">
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text02">
                      Home
                    </span>
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text03">
                      About
                    </span>
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text04">
                      Tour Packages
                    </span>
                    <span>Contact</span>
                  </div>
                  <SolidButton button="Explore places"></SolidButton>
                </div>
              </div>
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-follow-container">
                <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text06">
                  Follow us on
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link1"
                  >
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon05"
                    >
                      <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                    </svg>
                  </a>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link2"
                  >
                    <svg
                      viewBox="0 0 602.2582857142856 1024"
                      className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon07"
                    >
                      <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                    </svg>
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link3"
                  >
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon09"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container03">
          <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-pricing23 thq-section-padding">
            <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width thq-section-max-width">
              <div
                id="quote"
                className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container04"
              >
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-section-title">
                  <img
                    alt="image"
                    src="/Gallery/zuza-vibe10-schulen-1200w.webp"
                    className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image"
                  />
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-content">
                    <h2 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text07">
                      <span className="Heading">
                        Planen Sie Ihren jährlichen
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text09">
                        Schulausflug
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text10">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="Heading">mit mir. 🛬</span>
                    </h2>
                    <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text12">
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text13 STANDARD4">
                        Für Schulen und Klassenfahrten - maßgeschneiderte Reisen
                        nach Prag mit komplettem Programm, Unterkunft und
                        Verpflegung. 
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text14">
                        ⭐⭐⭐⭐⭐
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container05">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-column thq-card">
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-body">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon11"
                    >
                      <path d="M512 128l470 256v342h-86v-296l-384 210-470-256zM214 562l298 164 298-164v172l-298 162-298-162v-172z"></path>
                    </svg>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-price">
                      <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text15 thq-body-large">
                        Der Plan für Schulen
                      </p>
                      <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text16 thq-heading-3">
                        € Quote
                      </h3>
                    </div>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list">
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item">
                        <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                        <span>
                          <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text18">
                            Europäische Geschichte
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text19"></br>
                          <span>
                            - Johannes von Luxemburg bis zur Samtenen Revolution
                          </span>
                        </span>
                      </div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item1">
                        <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                        <span>
                          <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text22">
                            Ausflüge
                          </span>
                          <span>
                            {' '}
                            (nach Theresienstadt, Lidice, Mladá Boleslav,
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text24">
                            etc
                          </span>
                          <span>.)</span>
                        </span>
                      </div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item2">
                        <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                        <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text26">
                          <span>
                            Unterkunft
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text28">
                            (wahlweise)
                          </span>
                        </span>
                      </div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item3">
                        <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                        <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text29">
                          Verpflegung
                        </span>
                      </div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item4">
                        <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                        <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text30">
                          komplette Koordination mit dem Lehrer
                        </span>
                      </div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item5">
                        <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                        <span>beliebige Anzahl von Tagen</span>
                      </div>
                    </div>
                  </div>
                  <a
                    href="https://wa.me/00420721231933"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button thq-button-filled thq-button-animated"
                  >
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text32 thq-body-small">
                      Erstellen Sie Ihr maßgeschneidertes Programm
                    </span>
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text33">
                      Mit Zuz koordinieren
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-panel-3 thq-section-padding">
            <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width1 thq-section-max-width">
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-timeline">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step">
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container06">
                    <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text34 thq-heading-3">
                      Vorbereitung und Planung:
                    </h3>
                    <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-asd thq-heading-3">
                      <span>
                        „Kontaktieren Sie mich jetzt, um das beste Angebot zu
                        erhalten und den besten Preis zu sichern.“
                      </span>
                      <br></br>
                    </h3>
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text37 thq-body-small">
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text38">
                        Zielsetzung und Budget:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text39">
                        Definieren und konsultieren Sie die pädagogischen und
                        kulturellen Ziele der Reise und legen Sie das
                        Gesamtbudget fest.
                      </span>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text40"></br>
                      <br></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text42">
                        Reisedaten und Teilnehmer:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text43">
                        Wählen Sie geeignete Reisedaten aus und legen Sie die
                        Anzahl der Schüler, Lehrer und Begleitpersonen fest.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text46">
                        Vorbereitung der Schüler:
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text47">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text48">
                        Integrieren Sie Aspekte der Reise in den Lehrplan,
                        vermitteln Sie kulturelle Informationen über Prag und
                        lehren Sie grundlegende tschechische Ausdrücke.
                      </span>
                      <br></br>
                    </span>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container07">
                      <button
                        type="button"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button1 thq-button-filled"
                      >
                        Primary action
                      </button>
                      <button
                        type="button"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button2 thq-button-outline"
                      >
                        Seconday action
                      </button>
                    </div>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container08">
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container09"></div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress0"></div>
                    </div>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step1">
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container10">
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container11"></div>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress01"></div>
                  </div>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container12">
                    <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text50 thq-heading-3">
                      Zusammenarbeit mit Zuzka und logistische Planung:
                    </h3>
                    <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text51 thq-heading-3">
                      &quot;Monat vor der Reise&quot;
                    </h3>
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text52 thq-body-small">
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text53">
                        Reiseplan und Sicherheitsprotokolle:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text54">
                        Erarbeiten Sie gemeinsam mit dem Reisebüro eine
                        detaillierte Reiseroute, die Bildungsziele und wichtige
                        kulturelle Stätten enthält. Besprechen Sie
                        Sicherheitsmaßnahmen und Notfallprotokolle.
                      </span>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text55"></br>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text56"></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text57">
                        Reise- und Unterkunftsvorbereitungen:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text58">
                        Bestätigen Sie sichere und komfortable Transport- und
                        Unterkunftsmöglichkeiten. Planen Sie Mahlzeiten und
                        berücksichtigen Sie besondere Ernährungsbedürfnisse.
                      </span>
                    </span>
                  </div>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container13">
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container14"></div>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress02"></div>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step2">
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container15">
                    <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text59 thq-heading-3">
                      Ankunft und Check-in:
                    </h3>
                    <h3 className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text60 thq-heading-3">
                      | Tag 1: Anreise und Einführung
                    </h3>
                    <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text61 thq-body-small">
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text62"></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text63">
                        Ankunft und Check-in: Ankunft in Prag und Einchecken in
                        Ihr Hotel.
                      </span>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text64"></br>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text65"></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text66">
                        Willkommens-Brunch:
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text67">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text68">
                        Genießen Sie einen traditionellen tschechischen Brunch
                        in einem lokalen Café
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text69">
                        .
                      </span>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text70"></br>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text71"></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text72">
                        Altstädter Ring (Staroměstské náměstí): Erkunden Sie das
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text73">
                        Herz
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text74">
                        von Prag, einschließlich der Astronomischen Uhr (Orloj),
                        der Kirche Unserer Lieben Frau vor Týn und des Alten
                        Rathauses. 
                      </span>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text75">
                        Kurze Tour, schöne Einführung.
                      </span>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text76"></br>
                      <br className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text77"></br>
                      <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text78">
                        ... Wenn der Nachmittag nach Ihren Wünschen verlaufen
                        soll, kontaktieren Sie mich bitte, um einen Plan zu
                        erstellen.
                      </span>
                      <br></br>
                    </span>
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container16">
                      <button
                        type="button"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button3 thq-button-filled"
                      >
                        WhatsApp
                      </button>
                      <a
                        href="https://wa.me/00420721231933"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-contacte thq-button-filled"
                      >
                        WhatsApp
                      </a>
                      <button
                        type="button"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button4 thq-button-outline"
                      >
                        Rufen Sie mich für eine sofortige Beratung an
                      </button>
                      <Link
                        to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button5"
                      >
                        (+420) 721 231 933
                      </Link>
                    </div>
                  </div>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container17">
                    <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container18">
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container19"></div>
                      <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress03"></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question thq-body-large">
                <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text80">
                  Häufig gestellte Fragen:
                </span>
                <br></br>
              </p>
            </div>
          </div>
        </div>
        <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq8 thq-section-padding">
          <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question1 thq-body-large">
            Häufig gestellte Fragen
          </p>
          <a
            href="https://wa.me/00420721231933"
            target="_blank"
            rel="noreferrer noopener"
            className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link4"
          >
            <img
              alt="image"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/86f06aa0-1c52-43fb-93fc-8b7bc7bd9d0b/f2e20560-27a1-47e9-9aec-95361ca2a613?org_if_sml=111621&amp;force_format=original"
              className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image1"
            />
          </a>
          <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width2 thq-flex-row thq-section-max-width">
            <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list1 thq-flex-column">
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger">
                  <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question2 thq-body-large">
                    Welche Sicherheitsvorkehrungen werden während der Touren
                    getroffen, um die Sicherheit der Schüler zu gewährleisten?
                  </p>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container1">
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon25"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon27"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container22">
                  <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text82 thq-body-small">
                    <span>
                      Wir legen großen Wert auf die Sicherheit aller Teilnehmer,
                      insbesondere der Schüler. Unsere Tourguides sind geschult,
                      um in Notfällen richtig zu reagieren, und jede Tour
                      beginnt mit einer Sicherheitsunterweisung. Zudem sind alle
                      unsere Touren von einer Haftpflichtversicherung abgedeckt.
                      Wir empfehlen Lehrern, die Notfallkontakte der Schüler
                      bereitzuhalten und die Schüler vor der Tour über
                      Verhaltensregeln zu informieren.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq11">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger1">
                  <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question3 thq-body-large">
                    {' '}
                    Welche pädagogischen Inhalte werden in den Touren vermittelt
                    und wie sind sie in den Lehrplan integriert?
                  </p>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container2">
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon29"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon31"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container25">
                  <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text85 thq-body-small">
                    Unsere Touren sind speziell darauf ausgelegt,
                    Bildungsinhalte auf spannende und interaktive Weise zu
                    vermitteln. Themenbereiche wie Geschichte, Kunst, Kultur und
                    Architektur werden abgedeckt und durch Erzählungen und
                    Aktivitäten lebendig gemacht. Wir arbeiten eng mit Lehrern
                    zusammen, um sicherzustellen, dass die Inhalte mit den
                    Lernzielen und dem Lehrplan übereinstimmen. Auf Anfrage
                    können wir auch spezielle Themen anpassen und vertiefen.
                  </span>
                </div>
              </div>
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq12">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger2">
                  <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question4 thq-body-large">
                    Gibt es spezielle Rabatte oder Angebote für Schulgruppen?
                  </p>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container3">
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon33"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon35"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container28">
                  <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text86 thq-body-small">
                    <span>
                      Ja, wir bieten spezielle Tarife und Rabatte für
                      Schulgruppen an. Für jede Schulklasse gibt es pro zehn
                      Schüler einen Freiplatz für eine Begleitperson. Zudem
                      können wir maßgeschneiderte Tourpakete erstellen, die auf
                      das Budget und die Bedürfnisse Ihrer Gruppe zugeschnitten
                      sind. Bitte kontaktieren Sie uns im Voraus, um ein
                      individuelles Angebot zu erhalten und die Verfügbarkeit zu
                      prüfen.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Falls Sie weitere Fragen haben, zögern Sie bitte nicht,
                      uns zu kontaktieren. Wir freuen uns darauf, Ihnen und
                      Ihren Schülern eine unvergessliche und lehrreiche Reise
                      nach Prag zu ermöglichen!
                    </span>
                  </span>
                </div>
              </div>
              <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq13">
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger3">
                  <p className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question5 thq-body-large">
                    Welche Orte empfehlen Sie, um von Prag aus in die Geschichte
                    zu reisen?
                  </p>
                  <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container4">
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon37"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon39"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container31">
                  <span className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text91 thq-body-small">
                    Tagesausflüge von Prag aus umfassen Kutná Hora und das
                    Gebeinhaus von Sedlec, wo Sie die historische Stadt und ihre
                    berühmte Knochenkirche erkunden können; Burg Karlštejn, ein
                    beeindruckender mittelalterlicher Bau vor den Toren Prags;
                    das Konzentrationslager Theresienstadt, ein düsterer Besuch
                    des ehemaligen Konzentrationslagers und Ghettos aus dem
                    Zweiten Weltkrieg; sowie den Nationalpark Böhmische und
                    Sächsische Schweiz, wo Sie die natürliche Schönheit des
                    Parks mit Wanderungen und landschaftlichen Aussichten
                    erleben können.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <a
            href="https://wa.me/00420721231933"
            target="_blank"
            rel="noreferrer noopener"
            className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link5"
          >
            <img
              alt="image"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/86f06aa0-1c52-43fb-93fc-8b7bc7bd9d0b/f2e20560-27a1-47e9-9aec-95361ca2a613?org_if_sml=111621&amp;force_format=original"
              className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image2"
            />
          </a>
          <Link
            to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
            className="prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button6"
          >
            KONTAKT PAGE
          </Link>
        </div>
      </div>
    )
  }

export default PRAGMitZUZAFrSchulenUndKlassenfahrtenMageschneiderteReisenNachPragMitKomplettemProgrammUnterkunftUndVerpflegung
