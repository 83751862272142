import React from 'react'
import { Link } from 'react-router-dom'

import { DateTimePrimitive } from '@teleporthq/react-components'
import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import './prag-mit-zuza-exkluziv-private-touren.css'

const PRAGMitZUZAExkluzivPrivateTouren = (props) => {
  return (
    <div className="prag-mit-zuza-exkluziv-private-touren-container">
      <Helmet>
        <title>Travel Agency</title>
        <meta property="og:title" content="Travel Agency" />
      </Helmet>
      <div className="prag-mit-zuza-exkluziv-private-touren-top-container">
        <nav
          data-role="Header"
          className="prag-mit-zuza-exkluziv-private-touren-navbar"
        >
          <h1 className="prag-mit-zuza-exkluziv-private-touren-logo">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
          <div className="prag-mit-zuza-exkluziv-private-touren-right-side">
            <div className="prag-mit-zuza-exkluziv-private-touren-links-container">
              <Link
                to="/prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung"
                className="prag-mit-zuza-exkluziv-private-touren-navlink"
              >
                Für Schulen
              </Link>
              <a
                href="#CONTACT ME"
                className="prag-mit-zuza-exkluziv-private-touren-link"
              >
                Über mich
              </a>
              <a
                href="/prag-mit-zuza-exkluziv-private-touren-touren"
                className="prag-mit-zuza-exkluziv-private-touren-link01"
              >
                Tour Packages
              </a>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container01">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuza-exkluziv-private-touren-button Button button"
              >
                (+420) 721 231 933
              </Link>
            </div>
          </div>
          <div
            data-role="BurgerMenu"
            className="prag-mit-zuza-exkluziv-private-touren-burger-menu"
          >
            <svg
              viewBox="0 0 1024 1024"
              className="prag-mit-zuza-exkluziv-private-touren-burger-menu1"
            >
              <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            </svg>
          </div>
          <div
            data-role="MobileMenu"
            className="prag-mit-zuza-exkluziv-private-touren-mobile-menu"
          >
            <div className="prag-mit-zuza-exkluziv-private-touren-nav">
              <div className="prag-mit-zuza-exkluziv-private-touren-top">
                <h1>Travel</h1>
                <div
                  data-role="CloseMobileMenu"
                  className="prag-mit-zuza-exkluziv-private-touren-close-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="prag-mit-zuza-exkluziv-private-touren-icon03"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="prag-mit-zuza-exkluziv-private-touren-right-side1">
                <div className="prag-mit-zuza-exkluziv-private-touren-links-container1">
                  <span className="prag-mit-zuza-exkluziv-private-touren-text002">
                    Home
                  </span>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text003">
                    About
                  </span>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text004">
                    Tour Packages
                  </span>
                  <span>Contact</span>
                </div>
                <a
                  href="#main-section"
                  className="prag-mit-zuza-exkluziv-private-touren-link02"
                >
                  <SolidButton button="Explore places"></SolidButton>
                </a>
              </div>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-follow-container">
              <span className="prag-mit-zuza-exkluziv-private-touren-text006">
                Follow us on
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <div className="prag-mit-zuza-exkluziv-private-touren-icons-container">
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuza-exkluziv-private-touren-link03"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="prag-mit-zuza-exkluziv-private-touren-icon05"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuza-exkluziv-private-touren-link04"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="prag-mit-zuza-exkluziv-private-touren-icon07"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuza-exkluziv-private-touren-link05"
                >
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="prag-mit-zuza-exkluziv-private-touren-icon09"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="prag-mit-zuza-exkluziv-private-touren-hero">
          <div className="prag-mit-zuza-exkluziv-private-touren-content-container">
            <h1 className="prag-mit-zuza-exkluziv-private-touren-text007 Heading">
              ENTDECKEN SIE PRAG
            </h1>
            <h2 className="prag-mit-zuza-exkluziv-private-touren-subheading Subheading">
              PARADIES AUF ERDEN
            </h2>
            <h2 className="prag-mit-zuza-exkluziv-private-touren-subheading1">
              <span className="Subheading prag-mit-zuza-exkluziv-private-touren-text008">
                Deutschsprachige Führungen
              </span>
              <br></br>
            </h2>
            <img
              alt="image"
              src="/zuzana-manova-400h.png"
              className="prag-mit-zuza-exkluziv-private-touren-image"
            />
            <span className="prag-mit-zuza-exkluziv-private-touren-text010">
              <span className="prag-mit-zuza-exkluziv-private-touren-text011">
                Mit Zuzka als vertrauenswürdigem Führer können Sie sich in das
                Herz der Prager Geschichte wagen. Bestaunen Sie die
                ehrfurchtgebietende Prager Burg, überqueren Sie die
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text012">
                legendäre
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text013">
                Karlsbrücke, bewundern Sie die komplizierte Astronomische Uhr,
                und tauchen Sie ein in die tiefgründigen Geschichten des
                jüdischen Viertels. Jeder Schritt offenbart einen Teppich aus
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text014">
                Kunst
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text015">
                , Architektur und bemerkenswerten Persönlichkeiten, die die
                Identität Prags geprägt haben. Lassen Sie sich von Zuzka durch
                die faszinierenden Verflechtungen von Geschichte, Kunst und
                Kultur führen und erleben Sie eine tiefgehende Verbindung zu
                Prags reichem Erbe.
              </span>
              <br className="prag-mit-zuza-exkluziv-private-touren-text016"></br>
              <br className="prag-mit-zuza-exkluziv-private-touren-text017"></br>
              <span className="STANDARD1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <div className="prag-mit-zuza-exkluziv-private-touren-container02">
              <a
                href="#cta"
                autoFocus="true"
                className="prag-mit-zuza-exkluziv-private-touren-button1 Button button"
              >
                Free Consultation
              </a>
            </div>
            <a
              href="https://wa.me/00420721231933"
              target="_blank"
              rel="noreferrer noopener"
              className="prag-mit-zuza-exkluziv-private-touren-link06"
            >
              <img
                alt="image"
                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/86f06aa0-1c52-43fb-93fc-8b7bc7bd9d0b/f2e20560-27a1-47e9-9aec-95361ca2a613?org_if_sml=111621&amp;force_format=original"
                className="prag-mit-zuza-exkluziv-private-touren-image01"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        id="main-section"
        className="prag-mit-zuza-exkluziv-private-touren-main"
      >
        <span className="prag-mit-zuza-exkluziv-private-touren-text019">
          <span>
            Entdecken Sie die bezaubernde Geschichte Prags, während Sie durch
            die
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="prag-mit-zuza-exkluziv-private-touren-text021">
            alten
          </span>
          <br></br>
          <br></br>
          <span className="prag-mit-zuza-exkluziv-private-touren-text024">
            Straßen
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            schlendern und in die pulsierende Essenz der Stadt eintauchen.
          </span>
        </span>
        <div className="prag-mit-zuza-exkluziv-private-touren-cards-container">
          <div className="prag-mit-zuza-exkluziv-private-touren-container03">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1705993646193-571851957665?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDR8fHByYWclMjB0b3VyfGVufDB8fHx8MTcyMDM2NzQ0N3ww&amp;ixlib=rb-4.0.3&amp;w=400"
              className="prag-mit-zuza-exkluziv-private-touren-image02"
            />
            <div className="prag-mit-zuza-exkluziv-private-touren-container04">
              <span className="prag-mit-zuza-exkluziv-private-touren-text026">
                die Volle Erfarhrung
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text027">
                <span className="prag-mit-zuza-exkluziv-private-touren-text028">
                  Exklusive
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Stadtführungen für Deutsche und Österreichische Gäste 🇩🇪
                </span>
              </span>
              <div className="prag-mit-zuza-exkluziv-private-touren-container05">
                <a
                  href="https://wa.me/00420721231933"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuza-exkluziv-private-touren-link07 button"
                >
                  <span>
                    <span className="Button">Buchen jetzt </span>
                    <span>€€€</span>
                    <span className="Button"> </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-container06">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1616036902568-fa623d8f0c0a?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fHByYWclMjB0b3VyfGVufDB8fHx8MTcyMDM2NzQ0N3ww&amp;ixlib=rb-4.0.3&amp;w=300"
              className="prag-mit-zuza-exkluziv-private-touren-image03"
            />
            <div className="prag-mit-zuza-exkluziv-private-touren-container07">
              <span className="prag-mit-zuza-exkluziv-private-touren-text034">
                ein halber Tag (3 stunden)
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text035">
                <span>
                  Erkunden Sie die fesselnden Geheimnisse Prags mit Zuzka
                  Manovå, einer angesehenen Reiseleiterin mit
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="prag-mit-zuza-exkluziv-private-touren-text037">
                  30 Jahren Erfahrung!
                </span>
                <span> Buchen Sie jetzt per WhatsApp.</span>
              </span>
              <div className="prag-mit-zuza-exkluziv-private-touren-container08">
                <a
                  href="https://wa.me/00420721231933"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuza-exkluziv-private-touren-link08 button"
                >
                  <span className="prag-mit-zuza-exkluziv-private-touren-text039">
                    <span className="Button prag-mit-zuza-exkluziv-private-touren-text040">
                      Buchen jetzt
                    </span>
                    <span className="Button"> </span>
                    <span className="prag-mit-zuza-exkluziv-private-touren-text042">
                      €
                    </span>
                    <span className="Button"> </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-container09">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1557745133-ee31592739cb?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fHByYWd8ZW58MHx8fHwxNzIwMzY3MjgyfDA&amp;ixlib=rb-4.0.3&amp;w=300"
              className="prag-mit-zuza-exkluziv-private-touren-image04"
            />
            <div className="prag-mit-zuza-exkluziv-private-touren-container10">
              <span className="prag-mit-zuza-exkluziv-private-touren-text044">
                Privatführungen mit Flexibler Zeitgestaltung
              </span>
              <span className="prag-mit-zuza-exkluziv-private-touren-text045">
                <span className="STANDARD3">
                  Wählen Sie eine
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="prag-mit-zuza-exkluziv-private-touren-text047">
                  perfekte
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="STANDARD3">
                  und
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="prag-mit-zuza-exkluziv-private-touren-text049">
                  erfrischende
                </span>
                <span className="prag-mit-zuza-exkluziv-private-touren-text050">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="STANDARD3">
                  Reise nach Prag! Besuchen Sie Prag und fragen Sie mich alles
                  über Führungen oder Nachmittagsprogramme. Erleben Sie eine
                  unterhaltsame und lustige Reise.
                </span>
                <br className="STANDARD3"></br>
                <br className="STANDARD3"></br>
                <span className="STANDARD3">
                  Fragen Sie mich direkt auf
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="STANDARD3">WhatsA</span>
                <span className="STANDARD3">pp</span>
                <br className="STANDARD3"></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuza-exkluziv-private-touren-gallery3 thq-section-padding">
        <div className="prag-mit-zuza-exkluziv-private-touren-max-width thq-section-max-width">
          <div className="prag-mit-zuza-exkluziv-private-touren-section-title">
            <h2 className="prag-mit-zuza-exkluziv-private-touren-text058 thq-heading-2">
              Explore Our Gallery
            </h2>
            <span className="prag-mit-zuza-exkluziv-private-touren-text059">
              Entdecken Sie die bezaubernde Geschichte Prags, während Sie durch
              die alten Straßen schlendern und in die pulsierende Essenz der
              Stadt eintauchen.
            </span>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-container11">
            <div className="prag-mit-zuza-exkluziv-private-touren-content">
              <div className="prag-mit-zuza-exkluziv-private-touren-container12">
                <img
                  alt="Cityscape at sunset"
                  src="/Gallery/zuza-vibe3-1500w.webp"
                  loading="lazy"
                  className="prag-mit-zuza-exkluziv-private-touren-image1 thq-animated-card-bg-1 thq-img-ratio-16-9"
                />
              </div>
              <div className="prag-mit-zuza-exkluziv-private-touren-container13">
                <div className="prag-mit-zuza-exkluziv-private-touren-container14">
                  <img
                    alt="Historical landmark during daytime"
                    src="/Gallery/zuza-vibe5-500h.webp"
                    loading="lazy"
                    className="prag-mit-zuza-exkluziv-private-touren-image2 thq-img-ratio-4-3 thq-animated-card-bg-1"
                  />
                  <img
                    alt="Local market bustling with activity"
                    src="/Gallery/zuza-vibe11-500h.webp"
                    loading="lazy"
                    className="prag-mit-zuza-exkluziv-private-touren-image3 thq-img-ratio-4-3 thq-animated-card-bg-1"
                  />
                </div>
                <div className="prag-mit-zuza-exkluziv-private-touren-container15">
                  <img
                    alt="Tour guide explaining historical significance"
                    src="/Gallery/zuza-vibe6-500h.webp"
                    loading="lazy"
                    className="prag-mit-zuza-exkluziv-private-touren-image4 thq-img-ratio-4-3 thq-animated-card-bg-1"
                  />
                  <img
                    alt="Group of travelers enjoying a local dish"
                    src="/Gallery/zuza-vibe8-500h.webp"
                    className="prag-mit-zuza-exkluziv-private-touren-image5 thq-img-ratio-4-3 thq-animated-card-bg-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="CONTACT ME"
        className="prag-mit-zuza-exkluziv-private-touren-contact20 thq-section-padding"
      >
        <div className="prag-mit-zuza-exkluziv-private-touren-max-width1 thq-section-max-width">
          <div className="prag-mit-zuza-exkluziv-private-touren-container16">
            <div className="prag-mit-zuza-exkluziv-private-touren-container17">
              <div className="prag-mit-zuza-exkluziv-private-touren-container18">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1511707171634-5f897ff02aa9?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fHBob25lfGVufDB8fHx8MTcyMDM3MzMwN3ww&amp;ixlib=rb-4.0.3&amp;w=200"
                  loading="lazy"
                  className="prag-mit-zuza-exkluziv-private-touren-image05"
                />
                <h1 className="prag-mit-zuza-exkluziv-private-touren-text060">
                  Handy
                </h1>
                <a
                  href="tel:+420721231933"
                  className="prag-mit-zuza-exkluziv-private-touren-link09"
                >
                  <h3 className="prag-mit-zuza-exkluziv-private-touren-text061">
                    (+420) 721 23 19 33
                  </h3>
                </a>
              </div>
              <div className="prag-mit-zuza-exkluziv-private-touren-container19">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1563986768609-322da13575f3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDV8fGVtYWlsfGVufDB8fHx8MTcyMDM3Mzg0NHww&amp;ixlib=rb-4.0.3&amp;w=200"
                  loading="lazy"
                  className="prag-mit-zuza-exkluziv-private-touren-image06"
                />
                <h1 className="prag-mit-zuza-exkluziv-private-touren-text062">
                  Email
                </h1>
                <a
                  href="mailto:zuzanamanova@email.cz?subject=[web] Angebot für Ihre Stadtführung - Prag [online]"
                  className="prag-mit-zuza-exkluziv-private-touren-link10"
                >
                  <h3 className="prag-mit-zuza-exkluziv-private-touren-text063">
                    zuzanamanova@email.cz
                  </h3>
                </a>
              </div>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container20">
              <span className="prag-mit-zuza-exkluziv-private-touren-text064">
                Setzen Sie sich mit mir in Verbindung und fordern Sie einen
                kostenlosen Kostenvoranschlag sowie eine Beratung für Ihren
                nächsten Besuch an.
              </span>
            </div>
            <h2 className="prag-mit-zuza-exkluziv-private-touren-text065 thq-heading-2">
              KONTAKT AUFNEHMEN
            </h2>
            <div className="prag-mit-zuza-exkluziv-private-touren-container21">
              <h3 className="prag-mit-zuza-exkluziv-private-touren-text066">
                (+420) 721 23 19 33
              </h3>
            </div>
          </div>
        </div>
        <a
          href="https://wa.me/00420721231933"
          target="_blank"
          rel="noreferrer noopener"
          className="prag-mit-zuza-exkluziv-private-touren-link11"
        >
          <img
            alt="image"
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/86f06aa0-1c52-43fb-93fc-8b7bc7bd9d0b/f2e20560-27a1-47e9-9aec-95361ca2a613?org_if_sml=111621&amp;force_format=original"
            className="prag-mit-zuza-exkluziv-private-touren-image07"
          />
        </a>
        <div className="prag-mit-zuza-exkluziv-private-touren-container22">
          <div className="prag-mit-zuza-exkluziv-private-touren-container23">
            <div
              data-thq="slider"
              data-loop="true"
              data-autoplay="true"
              data-navigation="true"
              data-pagination="true"
              data-disable-autoplay-on-interaction="true"
              className="prag-mit-zuza-exkluziv-private-touren-slider swiper"
            >
              <div data-thq="slider-wrapper" className="swiper-wrapper">
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide01 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide02 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide03 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide04 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide05 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide06 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide07 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide08 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide09 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide10 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide11 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuza-exkluziv-private-touren-slider-slide12 swiper-slide"
                ></div>
              </div>
              <div className="prag-mit-zuza-exkluziv-private-touren-container24">
                <div className="prag-mit-zuza-exkluziv-private-touren-container25"></div>
              </div>
              <div
                data-thq="slider-pagination"
                className="prag-mit-zuza-exkluziv-private-touren-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
              >
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
              </div>
              <div
                data-thq="slider-button-prev"
                className="swiper-button-prev"
              ></div>
              <div
                data-thq="slider-button-next"
                className="swiper-button-next"
              ></div>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container26">
              <div className="prag-mit-zuza-exkluziv-private-touren-container27">
                <div className="prag-mit-zuza-exkluziv-private-touren-container28">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container29">
                    <span className="prag-mit-zuza-exkluziv-private-touren-text067">
                      Prag, die Goldene Stadt. Super Reise mit einer
                      fantastischen Reiseführerin. Kann ich nur jedem empfehlen.
                      👍Das Tempo war nicht zu gehetzt oder stressig. Man hatte
                      ausreichen Zeit sich umzusehen und alles mitzuverfolgen.
                    </span>
                  </div>
                  <div className="prag-mit-zuza-exkluziv-private-touren-container30">
                    <span className="prag-mit-zuza-exkluziv-private-touren-text068">
                      <span>
                        Zuza hat
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text070">
                        über
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text071">
                        30
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>Jahre Erfahrung, ihre Führungen sind aktuell.</span>
                    </span>
                  </div>
                </div>
                <div className="prag-mit-zuza-exkluziv-private-touren-container31">
                  <span className="prag-mit-zuza-exkluziv-private-touren-text073">
                    <span>
                      Entdecken Sie die faszinierende Geschichte und Kultur von
                      Prag mit unseren
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="prag-mit-zuza-exkluziv-private-touren-text075">
                      exklusiven
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      Stadtführungen! Lassen Sie sich von Zuzka, unserer
                      erfahrenen Stadtführerin, durch die malerischen Straßen
                      führen und entdecken Sie die Schönheit der Prager Burg und
                      des jüdischen Viertels... 
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuza-exkluziv-private-touren-container32">
        <div
          id="cta"
          className="prag-mit-zuza-exkluziv-private-touren-container33"
        >
          <form
            name="fast-quote"
            action="https://formsubmit.co/zuzanamanova@email.cz"
            method="POST"
            enctype="application/x-www-form-urlencoded"
            className="prag-mit-zuza-exkluziv-private-touren-form"
          >
            <div className="prag-mit-zuza-exkluziv-private-touren-container34">
              <div className="prag-mit-zuza-exkluziv-private-touren-container35">
                <div className="prag-mit-zuza-exkluziv-private-touren-container36">
                  <input
                    type="text"
                    name="name"
                    rows="Name"
                    required="true"
                    placeholder="Name:"
                    className="prag-mit-zuza-exkluziv-private-touren-textinput input"
                  />
                  <input
                    type="text"
                    name="contactinfo"
                    required="true"
                    placeholder="E-Mail Adresse oder Telefonnummer (optional)"
                    className="prag-mit-zuza-exkluziv-private-touren-textinput1 input"
                  />
                  <textarea
                    name="message"
                    placeholder="Hallo, ich besuche Prag (bald) und würde gerne wissen, ... Ich bin interessiert an ... ✒️"
                    className="prag-mit-zuza-exkluziv-private-touren-textarea textarea"
                  ></textarea>
                  <div className="prag-mit-zuza-exkluziv-private-touren-container37">
                    <span className="prag-mit-zuza-exkluziv-private-touren-date-time">
                      <DateTimePrimitive
                        format="ddd, MMM D, YYYY h:mm A"
                        date="Sun Jul 07 2024 21:27:47 GMT-0600 (severoamerický centrální standardní čas)"
                      ></DateTimePrimitive>
                    </span>
                    <div className="prag-mit-zuza-exkluziv-private-touren-container38">
                      <span className="prag-mit-zuza-exkluziv-private-touren-text077">
                        <span>
                          Fühlen Sie sich frei, eine Nachricht an die Tourguide
                          Zuzana Manova zu hinterlassen und erwarten Sie eine
                          Antwort innerhalb von
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="prag-mit-zuza-exkluziv-private-touren-text079">
                          maximal
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          48 Stunden, aber normalerweise nur wenige Stunden, an
                          die Kontaktinformationen, die Sie im Feld hinterlassen
                          haben.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prag-mit-zuza-exkluziv-private-touren-container39">
                <div className="prag-mit-zuza-exkluziv-private-touren-container40">
                  <button
                    type="submit"
                    className="prag-mit-zuza-exkluziv-private-touren-button2 button"
                  >
                    Request a quote
                  </button>
                  <div className="prag-mit-zuza-exkluziv-private-touren-container41">
                    <div className="prag-mit-zuza-exkluziv-private-touren-container42">
                      <input
                        type="checkbox"
                        required="true"
                        className="prag-mit-zuza-exkluziv-private-touren-checkbox"
                      />
                      <span className="prag-mit-zuza-exkluziv-private-touren-text081">
                        By submitting this form, you agree to have your data
                        processed in accordance with our Privacy Policy.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="prag-mit-zuza-exkluziv-private-touren-max-width2 thq-section-max-width">
        <h1 className="prag-mit-zuza-exkluziv-private-touren-text082">
          Kundenbewertungen
        </h1>
        <span className="prag-mit-zuza-exkluziv-private-touren-text083">
          Die Stadtführung mit Zuzka war unglaublich informativ und
          unterhaltsam. Ich habe so viel über Prag gelernt und kann es kaum
          erwarten, wiederzukommen!
        </span>
        <div className="prag-mit-zuza-exkluziv-private-touren-container43 thq-grid-2">
          <div className="prag-mit-zuza-exkluziv-private-touren-accent2-bg thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="prag-mit-zuza-exkluziv-private-touren-card thq-card"
              >
                <div className="prag-mit-zuza-exkluziv-private-touren-container44">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container45">
                    <img
                      alt="Bild von Julia Schmidt"
                      src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDY5fHxwZXJzb258ZW58MHx8fHwxNzIwMzgyOTMxfDA&amp;ixlib=rb-4.0.3&amp;w=200"
                      className="prag-mit-zuza-exkluziv-private-touren-image08"
                    />
                    <div className="prag-mit-zuza-exkluziv-private-touren-container46">
                      <strong className="prag-mit-zuza-exkluziv-private-touren-text084 thq-body-large">
                        Friedrich Bauer
                      </strong>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text085 thq-body-small">
                        --
                      </span>
                    </div>
                  </div>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text086 thq-body-small">
                    Vielen Dank an Zuzka für die wundervolle Tour! Es war ein
                    unvergessliches Wochenende mit Freunden in einem
                    österreichischen Dorf und wir haben besonders die Bootsfahrt
                    geliebt.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-accent2-bg1 thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="prag-mit-zuza-exkluziv-private-touren-card1 thq-card"
              >
                <div className="prag-mit-zuza-exkluziv-private-touren-container47">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container48">
                    <img
                      alt="Bild von Julia Schmidt"
                      src="https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDM3NTA3MXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=200"
                      className="prag-mit-zuza-exkluziv-private-touren-image09"
                    />
                    <div className="prag-mit-zuza-exkluziv-private-touren-container49">
                      <strong className="prag-mit-zuza-exkluziv-private-touren-text087 thq-body-large">
                        <span>Lydia S.</span>
                        <br></br>
                      </strong>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text090 thq-body-small">
                        Entrepreneur
                      </span>
                    </div>
                  </div>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text091 thq-body-small">
                    Herzlichen Dank an Zuzka für die fantastische Führung! Es
                    fühlte sich an wie eine Zeitreise.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-accent2-bg2 thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="prag-mit-zuza-exkluziv-private-touren-card2 thq-card"
              >
                <div className="prag-mit-zuza-exkluziv-private-touren-container50">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container51">
                    <img
                      alt="Bild von Julia Schmidt"
                      src="https://images.unsplash.com/photo-1560250097-0b93528c311a?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDM1fHxnZXJtYW4lMjBjZW98ZW58MHx8fHwxNzIwMzgyODM5fDA&amp;ixlib=rb-4.0.3&amp;w=200"
                      className="prag-mit-zuza-exkluziv-private-touren-image10"
                    />
                    <div className="prag-mit-zuza-exkluziv-private-touren-container52">
                      <strong className="prag-mit-zuza-exkluziv-private-touren-text092 thq-body-large">
                        Otto Keller
                      </strong>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text093 thq-body-small">
                        Marketing Specialist
                      </span>
                    </div>
                  </div>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text094 thq-body-small">
                    Die Stadtführung mit Zuzka war informativ und interessant.
                    Ich habe viel über die Geschichte Prags gelernt. Und macht
                    Spass!
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-accent2-bg3 thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="prag-mit-zuza-exkluziv-private-touren-card3 thq-card"
              >
                <div className="prag-mit-zuza-exkluziv-private-touren-container53">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container54">
                    <img
                      alt="Bild von Julia Schmidt"
                      src="https://images.unsplash.com/photo-1604983361403-ac3d3cbac41d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI5fHxwZXJzb24lMjB3b21hbiUyMG9sZHxlbnwwfHx8fDE3MjAzODI5OTh8MA&amp;ixlib=rb-4.0.3&amp;w=200"
                      className="prag-mit-zuza-exkluziv-private-touren-image11"
                    />
                    <div className="prag-mit-zuza-exkluziv-private-touren-container55">
                      <strong className="prag-mit-zuza-exkluziv-private-touren-text095 thq-body-large">
                        Julia Schmidt
                      </strong>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text096 thq-body-small">
                        Architektin
                      </span>
                    </div>
                  </div>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text097 thq-body-small">
                    Ich kann Zuzkas Stadtführung nur wärmstens empfehlen! Ihre
                    leidenschaftliche Art, die Geschichte Prags zu präsentieren,
                    hat mich wirklich beeindruckt. Es fühlte sich an, als würde
                    man in die Vergangenheit eintauchen.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-accent2-bg4 thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="prag-mit-zuza-exkluziv-private-touren-card4 thq-card"
              >
                <div className="prag-mit-zuza-exkluziv-private-touren-container56">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container57">
                    <img
                      alt="Bild von Julia Schmidt"
                      src="https://images.unsplash.com/photo-1485875437342-9b39470b3d95?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDY3fHxnZXJtYW4lMjBnaXJsJTIwb2xkfGVufDB8fHx8MTcyMDM4MjgxM3ww&amp;ixlib=rb-4.0.3&amp;w=200"
                      className="prag-mit-zuza-exkluziv-private-touren-image12"
                    />
                    <div className="prag-mit-zuza-exkluziv-private-touren-container58">
                      <strong className="prag-mit-zuza-exkluziv-private-touren-text098 thq-body-large">
                        Ingrid Weber
                      </strong>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text099 thq-body-small">
                        Expat
                      </span>
                    </div>
                  </div>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text100 thq-body-small">
                    Hallo Zuza! 😊 vielen Dank für die informative Stadtführung.
                    Die Tour bot einen faszinierenden Einblick in die Geschichte
                    Prags.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-accent2-bg5 thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="prag-mit-zuza-exkluziv-private-touren-card5 thq-card"
              >
                <div className="prag-mit-zuza-exkluziv-private-touren-container59">
                  <div className="prag-mit-zuza-exkluziv-private-touren-container60">
                    <img
                      alt="Bild von Julia Schmidt"
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI0fHxwcm9qZWt0JTIwbWFuYWdlciUyMGhlYWRzaG90fGVufDB8fHx8MTcyMDM4MjY4NHww&amp;ixlib=rb-4.0.3&amp;w=200"
                      className="prag-mit-zuza-exkluziv-private-touren-image13"
                    />
                    <div className="prag-mit-zuza-exkluziv-private-touren-container61">
                      <strong className="prag-mit-zuza-exkluziv-private-touren-text101 thq-body-large">
                        Hans Müller
                      </strong>
                      <span className="prag-mit-zuza-exkluziv-private-touren-text102 thq-body-small">
                        Projekt Manager
                      </span>
                    </div>
                  </div>
                  <span className="prag-mit-zuza-exkluziv-private-touren-text103 thq-body-small">
                    Vielen Dank an Zuzka für die faszinierende Führung! Sie war
                    sehr kenntnisreich und eine unglaublich energiegeladene
                    Reiseleiterin. Es war eine spannende Zeitreise durch die
                    Geschichte Prags und die jüdische Stadt im Zentrum.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="prag-mit-zuza-exkluziv-private-touren-container62">
          <div className="prag-mit-zuza-exkluziv-private-touren-container63">
            <div className="prag-mit-zuza-exkluziv-private-touren-container64">
              <a
                href="https://www.tourhq.com/guide/cz56896/zuzana-manova"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-exkluziv-private-touren-link12"
              >
                <img
                  alt="image"
                  src="/logo-200h.png"
                  className="prag-mit-zuza-exkluziv-private-touren-image14"
                />
              </a>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container65">
              <a
                href="https://www.tripadvisor.de/Attraction_Review-g274707-d10450040-Reviews-Private_Stadtfuhrungen_mit_Zuzana-Prague_Bohemia.html"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-exkluziv-private-touren-link13"
              >
                <img
                  alt="image"
                  src="/svg-image-1.svg"
                  className="prag-mit-zuza-exkluziv-private-touren-image15"
                />
              </a>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container66">
              <a
                href="https://g.co/kgs/1ajXTJD"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-exkluziv-private-touren-link14"
              >
                <img
                  alt="image"
                  src="/google-review-logo-200h.png"
                  className="prag-mit-zuza-exkluziv-private-touren-image16"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuza-exkluziv-private-touren-container67"></div>
      <div className="prag-mit-zuza-exkluziv-private-touren-footer">
        <div className="prag-mit-zuza-exkluziv-private-touren-menu">
          <h1 className="prag-mit-zuza-exkluziv-private-touren-logo2">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
          <div className="prag-mit-zuza-exkluziv-private-touren-links-container2">
            <div className="prag-mit-zuza-exkluziv-private-touren-container68">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-touren"
                className="prag-mit-zuza-exkluziv-private-touren-navlink1"
              >
                Tour packages
              </Link>
              <Link
                to="/"
                className="prag-mit-zuza-exkluziv-private-touren-navlink2"
              >
                Personalized offers
              </Link>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container69">
              <Link
                to="/"
                className="prag-mit-zuza-exkluziv-private-touren-navlink3"
              >
                Blog
              </Link>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-container70">
              <Link
                to="/prag-mit-zuza-legal"
                className="prag-mit-zuza-exkluziv-private-touren-navlink4"
              >
                Terms and conditions
              </Link>
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuza-exkluziv-private-touren-navlink5"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="prag-mit-zuza-exkluziv-private-touren-follow-container1">
            <span className="prag-mit-zuza-exkluziv-private-touren-text106">
              Follow my social media
            </span>
            <div className="prag-mit-zuza-exkluziv-private-touren-icons-container1">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-exkluziv-private-touren-link15"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="prag-mit-zuza-exkluziv-private-touren-icon11"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuza-exkluziv-private-touren-link16"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="prag-mit-zuza-exkluziv-private-touren-icon13"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuza-exkluziv-private-touren-container71 thq-section-padding">
        <div className="prag-mit-zuza-exkluziv-private-touren-max-width3 thq-section-max-width">
          <div className="prag-mit-zuza-exkluziv-private-touren-container72 thq-flex-row">
            <div className="prag-mit-zuza-exkluziv-private-touren-container73">
              <div className="prag-mit-zuza-exkluziv-private-touren-container74">
                <img
                  alt="Image1Alt"
                  src="https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1400"
                  className="prag-mit-zuza-exkluziv-private-touren-placeholder-image thq-img-ratio-16-9"
                />
              </div>
            </div>
            <div className="prag-mit-zuza-exkluziv-private-touren-column">
              <span className="prag-mit-zuza-exkluziv-private-touren-text107 thq-heading-2">
                Subscribe to newsletter
              </span>
              <p className="prag-mit-zuza-exkluziv-private-touren-text108 thq-body-small">
                Sie erhalten Werbebriefe und werden über einzigartige 
                Gelegenheiten informiert, einmalige Erfahrungen zu machen.
              </p>
              <div className="prag-mit-zuza-exkluziv-private-touren-container75">
                <form
                  action="https://formsubmit.co/zuzanamanova@email.cz"
                  method="POST"
                  enctype="application/x-www-form-urlencoded"
                  className="prag-mit-zuza-exkluziv-private-touren-form1"
                >
                  <div className="prag-mit-zuza-exkluziv-private-touren-container76">
                    <input
                      type="text"
                      name="email"
                      required="true"
                      placeholder="E-Mail Adresse"
                      className="input"
                    />
                  </div>
                  <div className="prag-mit-zuza-exkluziv-private-touren-container77">
                    <div className="prag-mit-zuza-exkluziv-private-touren-actions">
                      <button
                        type="submit"
                        className="prag-mit-zuza-exkluziv-private-touren-button3 thq-button-filled"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-thq="thq-dropdown"
        className="prag-mit-zuza-exkluziv-private-touren-thq-dropdown list-item"
      >
        <ul
          data-thq="thq-dropdown-list"
          className="prag-mit-zuza-exkluziv-private-touren-dropdown-list"
        >
          <li
            data-thq="thq-dropdown"
            className="prag-mit-zuza-exkluziv-private-touren-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="prag-mit-zuza-exkluziv-private-touren-dropdown-toggle"
            >
              <span className="prag-mit-zuza-exkluziv-private-touren-text109">
                Sub-menu Item
              </span>
            </div>
          </li>
          <li
            data-thq="thq-dropdown"
            className="prag-mit-zuza-exkluziv-private-touren-dropdown1 list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="prag-mit-zuza-exkluziv-private-touren-dropdown-toggle1"
            >
              <span className="prag-mit-zuza-exkluziv-private-touren-text110">
                Sub-menu Item
              </span>
            </div>
          </li>
          <li
            data-thq="thq-dropdown"
            className="prag-mit-zuza-exkluziv-private-touren-dropdown2 list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="prag-mit-zuza-exkluziv-private-touren-dropdown-toggle2"
            >
              <span className="prag-mit-zuza-exkluziv-private-touren-text111">
                Sub-menu Item
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default PRAGMitZUZAExkluzivPrivateTouren
