import React from 'react'
import { Link } from 'react-router-dom'

import { DateTimePrimitive } from '@teleporthq/react-components'
import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import './prag-mit-zuza-blog.css'

const PRAGMitZUZABLOG = (props) => {
  return (
    <div className="prag-mit-zuzablog-container">
      <Helmet>
        <title>PRAG-mit-ZUZA-BLOG - Travel Agency</title>
        <meta
          property="og:title"
          content="PRAG-mit-ZUZA-BLOG - Travel Agency"
        />
      </Helmet>
      <div className="prag-mit-zuzablog-top-container">
        <nav data-role="Header" className="prag-mit-zuzablog-navbar">
          <h1 className="prag-mit-zuzablog-logo">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
          <div className="prag-mit-zuzablog-right-side">
            <div className="prag-mit-zuzablog-links-container">
              <Link
                to="/prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung"
                className="prag-mit-zuzablog-navlink"
              >
                Für Schulen
              </Link>
              <a href="#CONTACT ME" className="prag-mit-zuzablog-link">
                Über mich
              </a>
              <a href="#main-section" className="prag-mit-zuzablog-link01">
                Tour Packages
              </a>
            </div>
            <div className="prag-mit-zuzablog-container01">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuzablog-button Button button"
              >
                (+420) 721 231 933
              </Link>
            </div>
          </div>
          <div data-role="BurgerMenu" className="prag-mit-zuzablog-burger-menu">
            <svg
              viewBox="0 0 1024 1024"
              className="prag-mit-zuzablog-burger-menu1"
            >
              <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="prag-mit-zuzablog-mobile-menu">
            <div className="prag-mit-zuzablog-nav">
              <div className="prag-mit-zuzablog-top">
                <h1>Travel</h1>
                <div
                  data-role="CloseMobileMenu"
                  className="prag-mit-zuzablog-close-menu"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="prag-mit-zuzablog-icon03"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="prag-mit-zuzablog-right-side1">
                <div className="prag-mit-zuzablog-links-container1">
                  <span className="prag-mit-zuzablog-text02">Home</span>
                  <span className="prag-mit-zuzablog-text03">About</span>
                  <span className="prag-mit-zuzablog-text04">
                    Tour Packages
                  </span>
                  <span>Contact</span>
                </div>
                <SolidButton button="Explore places"></SolidButton>
              </div>
            </div>
            <div className="prag-mit-zuzablog-follow-container">
              <span className="prag-mit-zuzablog-text06">
                Follow us on
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <div className="prag-mit-zuzablog-icons-container">
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuzablog-link02"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="prag-mit-zuzablog-icon05"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuzablog-link03"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="prag-mit-zuzablog-icon07"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="prag-mit-zuzablog-link04"
                >
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="prag-mit-zuzablog-icon09"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div id="main-section" className="prag-mit-zuzablog-main">
        <span className="prag-mit-zuzablog-text07">
          <span>
            Blog: Lernen Sie die wichtigsten Sehenswürdigkeiten kennen, ohne
            Prag zu besuchen
          </span>
          <br></br>
          <br></br>
          <span>
            Um aufgenommen zu werden: Melden Sie sich gerne bei mir, wenn Sie
            etwas über eine Sehenswürdigkeit in Prag erfahren möchten! Ich
            liefere Ihnen persönlich aktuelle Informationen per Telefon oder
            WhatsApp! 😇🍀
          </span>
          <br></br>
          <br></br>
          <span>Zuzana</span>
          <br></br>
        </span>
      </div>
      <div className="prag-mit-zuzablog-gallery3 thq-section-padding">
        <div className="prag-mit-zuzablog-max-width thq-section-max-width">
          <div className="prag-mit-zuzablog-section-title">
            <h2 className="prag-mit-zuzablog-text16 thq-heading-2">
              Private Stadtführung in Prag auf Deutsch
            </h2>
          </div>
          <div className="prag-mit-zuzablog-container02">
            <div className="prag-mit-zuzablog-content">
              <div className="prag-mit-zuzablog-container03">
                <img
                  alt="Cityscape at sunset"
                  src="/Gallery/zuza-vibe3-1500w.webp"
                  loading="lazy"
                />
              </div>
              <div className="prag-mit-zuzablog-container04">
                <div className="prag-mit-zuzablog-container05">
                  <img
                    alt="Historical landmark during daytime"
                    src="/zuzana-manova-touren-ein-500h.png"
                    loading="lazy"
                  />
                  <img
                    alt="Local market bustling with activity"
                    src="/zuzana-manova-cta-500h.webp"
                    loading="lazy"
                    className="prag-mit-zuzablog-image3 thq-img-ratio-4-3 thq-animated-card-bg-1"
                  />
                </div>
                <div className="prag-mit-zuzablog-container06">
                  <img
                    alt="Group of travelers enjoying a local dish"
                    src="/Gallery/zuza-vibe8-500h.webp"
                    className="prag-mit-zuzablog-image5 thq-img-ratio-4-3 thq-animated-card-bg-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="CONTACT ME"
        className="prag-mit-zuzablog-contact20 thq-section-padding"
      >
        <div className="prag-mit-zuzablog-max-width1 thq-section-max-width">
          <div className="prag-mit-zuzablog-container07">
            <div className="prag-mit-zuzablog-container08">
              <div className="prag-mit-zuzablog-container09">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1511707171634-5f897ff02aa9?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fHBob25lfGVufDB8fHx8MTcyMDM3MzMwN3ww&amp;ixlib=rb-4.0.3&amp;w=200"
                  loading="lazy"
                  className="prag-mit-zuzablog-image"
                />
                <h1 className="prag-mit-zuzablog-text17">Handy</h1>
                <a
                  href="tel:+420721231933"
                  className="prag-mit-zuzablog-link05"
                >
                  <h3 className="prag-mit-zuzablog-text18">
                    (+420) 721 23 19 33
                  </h3>
                </a>
              </div>
              <div className="prag-mit-zuzablog-container10">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1563986768609-322da13575f3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDV8fGVtYWlsfGVufDB8fHx8MTcyMDM3Mzg0NHww&amp;ixlib=rb-4.0.3&amp;w=200"
                  loading="lazy"
                  className="prag-mit-zuzablog-image1 thq-animated-card-bg-1 thq-img-ratio-16-9 prag-mit-zuzablog-image1"
                />
                <h1 className="prag-mit-zuzablog-text19">Email</h1>
                <a
                  href="mailto:zuzanamanova@email.cz?subject=[web] Angebot für Ihre Stadtführung - Prag [online]"
                  className="prag-mit-zuzablog-link06"
                >
                  <h3 className="prag-mit-zuzablog-text20">
                    zuzanamanova@email.cz
                  </h3>
                </a>
              </div>
            </div>
            <div className="prag-mit-zuzablog-container11">
              <span className="prag-mit-zuzablog-text21">
                Setzen Sie sich mit mir in Verbindung und fordern Sie einen
                kostenlosen Kostenvoranschlag sowie eine Beratung für Ihren
                nächsten Besuch an.
              </span>
            </div>
            <h2 className="prag-mit-zuzablog-text22 thq-heading-2">
              KONTAKT AUFNEHMEN
            </h2>
            <div className="prag-mit-zuzablog-container12">
              <h3 className="prag-mit-zuzablog-text23">(+420) 721 23 19 33</h3>
            </div>
          </div>
        </div>
        <a
          href="https://wa.me/00420721231933"
          target="_blank"
          rel="noreferrer noopener"
          className="prag-mit-zuzablog-link07"
        >
          <img
            alt="image"
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/86f06aa0-1c52-43fb-93fc-8b7bc7bd9d0b/f2e20560-27a1-47e9-9aec-95361ca2a613?org_if_sml=111621&amp;force_format=original"
            className="prag-mit-zuzablog-image2 thq-img-ratio-4-3 thq-animated-card-bg-1 prag-mit-zuzablog-image2"
          />
        </a>
        <div className="prag-mit-zuzablog-container13">
          <div className="prag-mit-zuzablog-container14">
            <div
              data-thq="slider"
              data-loop="true"
              data-autoplay="true"
              data-navigation="true"
              data-pagination="true"
              data-disable-autoplay-on-interaction="true"
              className="prag-mit-zuzablog-slider swiper"
            >
              <div data-thq="slider-wrapper" className="swiper-wrapper">
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide01 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide02 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide03 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide04 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide05 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide06 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide07 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide08 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide09 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide10 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide11 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="prag-mit-zuzablog-slider-slide12 swiper-slide"
                ></div>
              </div>
              <div className="prag-mit-zuzablog-container15">
                <div className="prag-mit-zuzablog-container16"></div>
              </div>
              <div
                data-thq="slider-pagination"
                className="prag-mit-zuzablog-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
              >
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
              </div>
              <div
                data-thq="slider-button-prev"
                className="swiper-button-prev"
              ></div>
              <div
                data-thq="slider-button-next"
                className="swiper-button-next"
              ></div>
            </div>
            <div className="prag-mit-zuzablog-container17">
              <div className="prag-mit-zuzablog-container18">
                <div className="prag-mit-zuzablog-container19">
                  <div className="prag-mit-zuzablog-container20">
                    <span className="prag-mit-zuzablog-text24">
                      Prag, die Goldene Stadt. Super Reise mit einer
                      fantastischen Reiseführerin. Kann ich nur jedem empfehlen.
                      👍Das Tempo war nicht zu gehetzt oder stressig. Man hatte
                      ausreichen Zeit sich umzusehen und alles mitzuverfolgen.
                    </span>
                  </div>
                  <div className="prag-mit-zuzablog-container21">
                    <span className="prag-mit-zuzablog-text25">
                      <span>
                        Zuza hat
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuzablog-text27">
                        über
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="prag-mit-zuzablog-text28">
                        30
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>Jahre Erfahrung, ihre Führungen sind aktuell.</span>
                    </span>
                  </div>
                </div>
                <div className="prag-mit-zuzablog-container22">
                  <span className="prag-mit-zuzablog-text30">
                    <span>
                      Entdecken Sie die faszinierende Geschichte und Kultur von
                      Prag mit unseren
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="prag-mit-zuzablog-text32">
                      exklusiven
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      Stadtführungen! Lassen Sie sich von Zuzka, unserer
                      erfahrenen Stadtführerin, durch die malerischen Straßen
                      führen und entdecken Sie die Schönheit der Prager Burg und
                      des jüdischen Viertels... 
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuzablog-container23">
        <div id="cta" className="prag-mit-zuzablog-container24">
          <form
            name="fast-quote"
            action="https://formsubmit.co/zuzanamanova@email.cz"
            method="POST"
            enctype="application/x-www-form-urlencoded"
            className="prag-mit-zuzablog-form"
          >
            <div className="prag-mit-zuzablog-container25">
              <div className="prag-mit-zuzablog-container26">
                <div className="prag-mit-zuzablog-container27">
                  <input
                    type="text"
                    name="name"
                    rows="Name"
                    required="true"
                    placeholder="Name:"
                    className="prag-mit-zuzablog-textinput input"
                  />
                  <input
                    type="text"
                    name="contactinfo"
                    required="true"
                    placeholder="E-Mail Adresse oder Telefonnummer (optional)"
                    className="prag-mit-zuzablog-textinput1 input"
                  />
                  <textarea
                    name="message"
                    placeholder="Hallo, ich besuche Prag (bald) und würde gerne wissen, ... Ich bin interessiert an ... ✒️"
                    className="prag-mit-zuzablog-textarea textarea"
                  ></textarea>
                  <div className="prag-mit-zuzablog-container28">
                    <span className="prag-mit-zuzablog-date-time">
                      <DateTimePrimitive
                        format="DD/MM/YYYY"
                        date="Sun Jul 07 2024 21:27:47 GMT-0600 (severoamerický centrální standardní čas)"
                      ></DateTimePrimitive>
                    </span>
                    <div className="prag-mit-zuzablog-container29">
                      <span className="prag-mit-zuzablog-text34">
                        <span>
                          Fühlen Sie sich frei, eine Nachricht an die Tourguide
                          Zuzana Manova zu hinterlassen und erwarten Sie eine
                          Antwort innerhalb von
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="prag-mit-zuzablog-text36">
                          maximal
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          48 Stunden, aber normalerweise nur wenige Stunden, an
                          die Kontaktinformationen, die Sie im Feld hinterlassen
                          haben.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prag-mit-zuzablog-container30">
                <div className="prag-mit-zuzablog-container31">
                  <button
                    type="submit"
                    className="prag-mit-zuzablog-button1 button"
                  >
                    Request a quote
                  </button>
                  <div className="prag-mit-zuzablog-container32">
                    <div className="prag-mit-zuzablog-container33">
                      <input
                        type="checkbox"
                        required="true"
                        className="prag-mit-zuzablog-checkbox"
                      />
                      <span className="prag-mit-zuzablog-text38">
                        By submitting this form, you agree to have your data
                        processed in accordance with our Privacy Policy.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="prag-mit-zuzablog-footer">
        <div className="prag-mit-zuzablog-menu">
          <h1 className="prag-mit-zuzablog-logo2">
            <span>PRAG mit ZUZA</span>
            <br></br>
          </h1>
          <div className="prag-mit-zuzablog-links-container2">
            <div className="prag-mit-zuzablog-container34">
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-touren"
                className="prag-mit-zuzablog-navlink1"
              >
                Tour packages
              </Link>
              <Link to="/" className="prag-mit-zuzablog-navlink2">
                Personalized offers
              </Link>
            </div>
            <div className="prag-mit-zuzablog-container35">
              <Link to="/" className="prag-mit-zuzablog-navlink3">
                Blog
              </Link>
            </div>
            <div className="prag-mit-zuzablog-container36">
              <Link
                to="/prag-mit-zuza-legal"
                className="prag-mit-zuzablog-navlink4"
              >
                Terms and conditions
              </Link>
              <Link
                to="/prag-mit-zuza-exkluziv-private-touren-contact-page"
                className="prag-mit-zuzablog-navlink5"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="prag-mit-zuzablog-follow-container1">
            <span className="prag-mit-zuzablog-text41">
              Follow my social media
            </span>
            <div className="prag-mit-zuzablog-icons-container1">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuzablog-link08"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="prag-mit-zuzablog-icon11"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="prag-mit-zuzablog-link09"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="prag-mit-zuzablog-icon13"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="prag-mit-zuzablog-container37 thq-section-padding">
        <div className="prag-mit-zuzablog-max-width2 thq-section-max-width">
          <div className="prag-mit-zuzablog-container38 thq-flex-row">
            <div className="prag-mit-zuzablog-container39">
              <div className="prag-mit-zuzablog-container40">
                <img
                  alt="Image1Alt"
                  src="https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1400"
                  className="prag-mit-zuzablog-placeholder-image thq-img-ratio-16-9"
                />
              </div>
            </div>
            <div className="prag-mit-zuzablog-column">
              <span className="prag-mit-zuzablog-text42 thq-heading-2">
                Subscribe to newsletter
              </span>
              <p className="prag-mit-zuzablog-text43 thq-body-small">
                Sie erhalten Werbebriefe und werden über einzigartige 
                Gelegenheiten informiert, einmalige Erfahrungen zu machen.
              </p>
              <div className="prag-mit-zuzablog-container41">
                <form
                  action="https://formsubmit.co/zuzanamanova@email.cz"
                  method="POST"
                  enctype="application/x-www-form-urlencoded"
                  className="prag-mit-zuzablog-form1"
                >
                  <div className="prag-mit-zuzablog-container42">
                    <input
                      type="text"
                      name="email"
                      required="true"
                      placeholder="E-Mail Adresse"
                      className="input"
                    />
                  </div>
                  <div className="prag-mit-zuzablog-container43">
                    <div className="prag-mit-zuzablog-actions">
                      <button
                        type="submit"
                        className="prag-mit-zuzablog-button2 thq-button-filled"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-thq="thq-dropdown"
        className="prag-mit-zuzablog-thq-dropdown list-item"
      >
        <ul
          data-thq="thq-dropdown-list"
          className="prag-mit-zuzablog-dropdown-list"
        >
          <li
            data-thq="thq-dropdown"
            className="prag-mit-zuzablog-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="prag-mit-zuzablog-dropdown-toggle"
            >
              <span className="prag-mit-zuzablog-text44">Sub-menu Item</span>
            </div>
          </li>
          <li
            data-thq="thq-dropdown"
            className="prag-mit-zuzablog-dropdown1 list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="prag-mit-zuzablog-dropdown-toggle1"
            >
              <span className="prag-mit-zuzablog-text45">Sub-menu Item</span>
            </div>
          </li>
          <li
            data-thq="thq-dropdown"
            className="prag-mit-zuzablog-dropdown2 list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="prag-mit-zuzablog-dropdown-toggle2"
            >
              <span className="prag-mit-zuzablog-text46">Sub-menu Item</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default PRAGMitZUZABLOG
